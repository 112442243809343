/* src/Login.css */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000000;
    font-family: Arial, sans-serif;
}
.app-logo {
    padding: 24px;
    width: 180px;
    margin-top: -100px;
}
.login-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: white;
}

.login-container form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-container label {
    margin-bottom: 8px;
    font-weight: bold;
}

.login-container input {
    margin-bottom: 12px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.login-container button {
    padding: 10px;
    font-size: 16px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-container button:hover {
    background-color: #357ae8;
}

.login-container p {
    color: red;
    margin-top: 10px;
}
