/* Home.css */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.home-container {
    width: 100%; /* Larghezza completa dello schermo */
    padding: 20px;
    box-sizing: border-box; /* Include padding e bordo nella larghezza e altezza totale */
    text-align: center;
}

.filter-container {
    margin-bottom: 20px;
}

.filter-buttons {
    padding: 24px;
}

.show-games-button {
    padding: 10px 20px;
    background-color: #4CAF50; /* Colore di sfondo verde */
    color: white; /* Testo bianco */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease; /* Effetto di transizione sul cambio di colore di sfondo */
    margin-bottom: 20px;
}

.show-games-button:focus {
    outline: none; /* Rimuove il bordo evidenziato al focus */
}

.show-games-button:active {
    background-color: #36733a; /* Colore di sfondo verde più scuro quando premuto */
}

.red-button {
    background-color: #ff4d4d; /* Colore di sfondo rosso */
}

.provider {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: left;
}

.provider-name {
    padding-left: 6px;
    text-align: left;
}

.provider-name .info {
    font-size: 0.8em;
}

.bold-text {
    font-weight: bold;
}


.provider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.provider-progress {
    display: flex;
    align-items: center;

    width: 80px;
}

.expand-button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    height: 25px;
}

.expand-button:hover {
    background-color: #0056b3;
}

.provider-games {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Griglia flessibile */
    gap: 10px; /* Spazio tra gli elementi della griglia */
}

.flex-box {
    display: flex;
    margin-top: auto;
}

.flex-box-c > div {
    margin: 4px 0;
}

strong {
    color: #333;
}


.jc-center {
    justify-content: center; /* Centra i contenuti lungo l'asse principale (orizzontale) */
}

.flex-box-col {
    display: flex;
    flex-direction: column;
}

.flex-box-c {
    flex: 1; /* Distribuisce lo spazio disponibile in modo uniforme tra i figli */
    text-align: center; /* Centra il testo all'interno dei blocchi */
}

.flex-1 {
    flex: 1
}

.padding-top-8 {
    padding-top: 8px;
}

.padding-8 {
    padding: 8px;
}

.padding-16 {
    padding: 16px;
}

.highlight {
    background-color: yellow;
}

.platform-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.platform-button {
    padding: 10px 20px;
    background-color: grey;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1 1 100px;
}

.platform-button.selected {
    background-color: green;
}

.progress-60 {
    width: 60px;
}

.progress-100 {
    width: 100px;
}

.gallery-button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.gallery-button:hover {
    background-color: #0056b3;
}

.game-status {
    padding: 6px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
}

.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
