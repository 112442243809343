/* public/index.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}
