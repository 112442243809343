/* src/Legend.css */
.legend {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.legend h2 {
    margin-bottom: 10px;
}

.legend ul {
    list-style-type: none;
    padding: 0;
}

.legend li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.legend .emoji {
    font-size: 1.5em;
    margin-right: 10px;
}
