/* styles.css */

.game:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.date {
    font-size: 0.7em;
    color: #555;
}

.provider-games .game-card {
    border: 1px solid #eee;
    padding: 6px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-sizing: border-box; /* Include padding e bordo nella larghezza e altezza totale */
    width: 100%; /* Larghezza completa del container */
    text-align: left;
}

.details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.details p {
    margin: 0;
}

.platform {
    margin-left: 8px;
}

.anchored {
    align-self: flex-end; /* Ancora questo elemento al fondo del box */
}

.title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5em; /* Altezza della linea */
    height: 3em; /* Due linee di testo */
    max-height: 3em; /* Due linee di testo */
}
.game-image {
    width: 100%;
    max-width: 200px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Stile per la modalità fullscreen */
.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Assicurati che il modal sia sopra gli altri elementi */
    cursor: pointer;
}

/* Stile per l'immagine a schermo intero */
.fullscreen-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Effetto di zoom sull'immagine a schermo intero */
.fullscreen-image:hover {
    transform: scale(1.05);
}